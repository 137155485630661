<template>
  <v-app>
    <base-app-bar />
    
    <base-view />

    <footer-view />
    <!-- <mobile-navigation /> -->
    <renew-token />
  
  </v-app>
</template>

<script>
import RenewToken from '../../components/global/RenewToken.vue';

  export default {
    name: 'BaseLayout',
    components: {
    BaseAppBar: () => import('./AppBar'),
    BaseView: () => import('./View'),
    FooterView: () => import('./Footer'),
    MobileNavigation: () => import('./MobileNavigationBar'),
    RenewToken
},
  }
</script>
